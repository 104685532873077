import React, { useMemo, useState, useEffect } from "react";
import { Alert } from "reactstrap";
import {  getPrincActivos, resetPrincipiosActivosFlag, deletePrincActivo} from "../../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

const ListaPrincipioActivo = () => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const principiosActivosPageData = createSelector(selectLayoutState, (state) => ({
    principiosActivos: state.PrincipiosActivos.principiosActivos,
    error: state.PrincipiosActivos.error,
    loading: state.PrincipiosActivos.loading,
    errorMsg: state.PrincipiosActivos.errorMsg,
    colorMsg: state.PrincipiosActivos.colorMsg,
  }));

  // Inside your component
  const { principiosActivos, error, loading, errorMsg, colorMsg } =
    useSelector(principiosActivosPageData);

  console.log("principiosActivos:", principiosActivos);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);

  useEffect(() => {
    if (errorMsg) {
      console.log("useEffect errorMsg is true");
      setTimeout(() => {
        dispatch(resetPrincipiosActivosFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    console.log("useEffect loading is true");
      dispatch(getPrincActivos());
  }, [dispatch, loading]);



  const [deleteModal, setDeleteModal] = useState(false);
  const [borrarSolicitud, setBorrarSolicitud] = useState({});

  const onClickDelete = (borrar) => {
    if (borrar) {
      console.log("Deleting lab with id:", borrarSolicitud.id);
      dispatch(deletePrincActivo(borrarSolicitud.id));
    }

    setDeleteModal(false);
  };

  const solicitudDelete = (solicitud) => {
    setBorrarSolicitud(solicitud);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        header: "Principio Activo",
        accessorKey: "nombre",
        enableColumnFilter: false,
      },
      {
        header: "Estatus",
        cell: (cell) => {
          return (
            <React.Fragment>
              {
                cell.getValue()  ? 
                ( <span className="badge bg-success-subtle text-success text-uppercase">Activo</span> ) : 
                ( <span className="badge bg-danger-subtle text-danger text-uppercase"> No Activo </span>)
                }
            </React.Fragment>
          );
        },
        accessorKey: "activo",
        enableColumnFilter: false,
      },
      {
        header: "Acción",
        accessorKey: "accion",
        enableColumnFilter: false,
        cell: (cell) => {
          const rowId = cell.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/principiosactivos/editar/${rowId}/`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-28" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Editar
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cell.row.original;
                  solicitudDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />

                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Eliminar
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>

      {errorMsg && errorMsg ? <Alert color={colorMsg}> {error} </Alert> : null}

      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => {
          onClickDelete(true);
        }}
      />

      <TableContainer
        columns={columns || []}
        data={principiosActivos[0] || []}
        isGlobalFilter={true}
        customPageSize={5}
        SearchPlaceholder="Buscar..."
        divClass="table-responsive"
      />
    </React.Fragment>
  );
};

export { ListaPrincipioActivo };
