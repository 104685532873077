import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
//Faqs
import Preguntas from "../pages/Faqs/Preguntas";

//Solicitudes
import ListView from "../pages/Solicitudes/ListView";
import SolicitudDetalle from "../pages/Solicitudes/DetailsView";

//Grupo Familiar
import ListaFamiliares from "../pages/Familiares/ListView";

//Enfermedades Cronicas
import EnfermedadesCronicas from "../pages/Cronicas/index";

//Laboratorios
import Laboratorios from "../pages/Laboratorios/index";
import LaboratorioListar from "../pages/Laboratorios/LaboratorioListar";
import LaboratorioCrear from "../pages/Laboratorios/LaboratorioCrear";
import LaboratorioEditar from "../pages/Laboratorios/LaboratorioEditar";

//Clientes
import ClienteListar from "../pages/Clientes/ClienteListar";
import ClienteVer from "../pages/Clientes/ClienteVer";
import ClienteCrear from "../pages/Clientes/ClienteCrear/";
import ClienteEditar from "../pages/Clientes/ClienteEditar";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//Farmacias
import FarmaciaListar from "../pages/Farmacias/FarmaciaListar";
import FarmaciaVer from "../pages/Farmacias/FarmaciaVer";
import FarmaciaCrear from "../pages/Farmacias/FarmaciaCrear";
import FarmaciaEditar from "../pages/Farmacias/FarmaciaEditar";

// Planilla Estrategica 
import PlanillaEstrategica from "../pages/PlanillaEstrategica/index";
import PlanillaEstrategicaListar from "../pages/PlanillaEstrategica/PlanillaEstrategicaListar";
import PlanillaEstrategicaCrear from "../pages/PlanillaEstrategica/PlanillaEstrategicaCrear";
import PlanillaEstrategicaEditar from "../pages/PlanillaEstrategica/PlanillaEstrategicaEditar";

//Usuarios
import UsuariosListar from "../pages/Usuarios/UsuarioListar";
import UsuariosCrear from "../pages/Usuarios/UsuarioCrear";
import UsuariosEditar from "../pages/Usuarios/UsuarioEditar";

//Lista de Servicio
import ServicioDeEntrega from "../pages/ServiciosDeEntrega/index";
import ServicioDeEntregaListar from "../pages/ServiciosDeEntrega/ServiciosDeEntregaListar";
import ServicioDeEntregaCrear from "../pages/ServiciosDeEntrega/ServiciosDeEntregaCrear";
import ServicioDeEntregaEditar from "../pages/ServiciosDeEntrega/ServiciosDeEntregaEditar";

//Delivery
import Delivery from "../pages/Delivery/index";
import DeliveryListar from "../pages/Delivery/Listar";

//Motorizados
import Motorizado from "../pages/Motorizado/index";
import MotorizadoListar from "../pages/Motorizado/Listar";

//Principios Activos
//import PrincipiosActivos from "../pages/PrincipiosActivos/index";
import PrincipioActivoListar from "../pages/PrincipiosActivos/PrincipioActivoListar";
import PrincipioActivoCrear from "../pages/PrincipiosActivos/PrincipioActivoCrear";
import PrincipioActivoEditar from "../pages/PrincipiosActivos/PrincipioActivoEditar";


// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/faqs", component: <Preguntas /> },

  //Solicitudes
  { path: "/solicitudes", component: <ListView /> },
  { path: "/solicitud/ver/:id", component: <SolicitudDetalle /> },

  //Familiares
  { path: "/grupo-familiar", component: <ListaFamiliares /> },

  { path: "/enfermedades-cronicas", component: <EnfermedadesCronicas /> },

  //Laboratorios
  { path: "/laboratorios", component: <LaboratorioListar /> },
  { path: "/laboratorios/editar/:id", component: <LaboratorioEditar  /> },
  { path: "/laboratorios/crear", component: <LaboratorioCrear  /> },
  
  //Usuarios
  { path: "/usuarios", component: <UsuariosListar /> },
  { path: "/usuarios/crear", component: <UsuariosCrear /> },
  { path: "/usuarios/editar/:id", component: <UsuariosEditar /> },

  //Servicio de Entrega
/*   { path: "/servicio-de-entrega/", component: <ServicioDeEntregaListar /> },
  { path: "/servicio-de-entrega/crear/", component: <ServicioDeEntregaCrear /> },
  { path: "/servicio-de-entrega/editar/:id", component: <ServicioDeEntregaEditar /> }, */

  //Delivery
  { path: "/delivery/", component: <DeliveryListar /> },

  //Motorizado
  { path: "/motorizado/", component: <MotorizadoListar /> },

  //Planilla Estrategica
  { path: "/planilla-estrategica/", component: <PlanillaEstrategicaListar /> },
  { path: "/planilla-estrategica/crear/", component: <PlanillaEstrategicaCrear /> },
  { path: "/planilla-estrategica/editar/:id", component: <PlanillaEstrategicaEditar /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  //Principios Activos
  { path: "/principiosactivos", component: <PrincipioActivoListar /> },
  { path: "/principiosactivos/editar/:id", component: <PrincipioActivoEditar  /> },
  { path: "/principiosactivos/crear", component: <PrincipioActivoCrear  /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
