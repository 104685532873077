import React, { useEffect, useState } from "react";
import {
  getPrincActivos,
  getPlanillas,
  getLabs,
  listaMedicamentos,
  editSolicitud,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Label,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import Select from "react-select";

const SolicitudMedicamentos = ({
  solicitud,
  setShowdetails,
  setShowCamposInfo,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrincActivos());
    dispatch(getPlanillas());
    dispatch(getLabs());
    removeDivTabla();
    setShowCamposInfo(false);
  }, [dispatch]);

  //Ver lista de Principios activos
  const [showListaPrincipiosActivos, setShowListaPrincipiosActivos] =
    useState(true);

  //OBTENER MEDICAMENTOS
  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    medicamentos: state.Solicitudes.medicamentos,
  }));

  // Inside your component
  const { medicamentos } = useSelector(pageData);

  const [medicamentosFarmabien, setMedicamentosFarmabien] = useState([]);
  const [formattedPrincipios, setFormattedPrincipios] = useState([]);

  //console.log("<<< medicamentos: >>>", medicamentos);

  useEffect(() => {
    if (medicamentos && formattedPrincipios.length > 0) {
      const comparacion = compararMedicamentosSolicitados(
        formattedPrincipios,
        medicamentos
      );

      setMedicamentosFarmabien(comparacion);

      actualizarTabla(comparacion);
    }
  }, [medicamentos, formattedPrincipios]);

  const [showSearchButton, setShowSearchButton] = useState(true);
  const [showCotillonButton, setShowCotillonButton] = useState(false);

  // Obtener los laboratorios
  const selectLabsState = (state) => state.Laboratorios;
  const labsPageData = createSelector(selectLabsState, (state) => state);
  const { laboratorios } = useSelector(labsPageData);
  //console.log("laboratorios:", laboratorios);

  // Obtener los principios activos
  const selectPrincActState = (state) => state.PrincipiosActivos;
  const princActPageData = createSelector(
    selectPrincActState,
    (state) => state
  );
  const { principiosActivos } = useSelector(princActPageData);
  //console.log("principiosActivos:", principiosActivos[0]);

  // Obtener las planillas
  const selectPlanillaState = (state) => state.PlanillaEstrategica;
  const planillasPageData = createSelector(
    selectPlanillaState,
    (state) => state
  );
  const { planillas } = useSelector(planillasPageData);
  //console.log("planillas:", planillas);

  const [principiosActivosEliminar, setPrincipiosActivosEliminar] = useState(
    []
  );
  const [laboratoriosEliminar, setLaboratoriosEliminar] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(planillas) &&
      planillas.length > 0 &&
      Array.isArray(planillas[0]) &&
      planillas[0].length > 0
    ) {
      setPrincipiosActivosEliminar(planillas[0][0].principiosactivos);
      setLaboratoriosEliminar(planillas[0][0].laboratorios);
    }
  }, [planillas]);

  useEffect(() => {
    if (solicitud.medicamentos && solicitud.medicamentos.length > 0) {
      const tablaHtml = verCotillon();
      const divTabla = document.getElementById("farme__divTabla");
      if (divTabla) {
        divTabla.innerHTML = tablaHtml;
      }

      //activamos ver delivery
      setShowCamposInfo(true);
      
    } else {
      removeDivTabla();
    }
  }, [solicitud]);

  //console.log("principiosActivosEliminar:", principiosActivosEliminar);
  //console.log("laboratoriosEliminar:", laboratoriosEliminar);

  // Filter out elements from principiosActivos[0] that appear in principiosActivosEliminar
  const filteredPrincipiosActivos = principiosActivos[0]?.filter((item) => {
    //console.log("Item ID:", item.id);
    const isInElimList = principiosActivosEliminar.some(
      (elimItem) => elimItem.id === item.id
    );
    //console.log("¿Está en la lista de eliminación?", isInElimList);
    return !isInElimList;
  });

  /*
   *   Lista de Medicamentos - Por Principio Activos (Lista Dinamica)
   */
  const [listaPrincipiosActivos, setListaPrincipiosActivos] = useState([
    { principioActivo: null, dias: "", dosis: "" },
  ]);

  // console.log("listaPrincipiosActivos:", listaPrincipiosActivos);

  const [selectedPrincipioActivo, setSelectedPrincipioActivo] = useState(null);

  /**
   * Generates an array of single-select options for a given list of principio activo objects.
   * @param {Array} principiosActivos - The list of principio activo objects.
   * @returns {Array} An array of objects with 'value' and 'label' properties, each representing a single-select option.
   */
  const generateSingleOptionsPrincipioActivo = (principiosActivos) => {
    if (!principiosActivos || !Array.isArray(principiosActivos)) {
      console.error("Invalid input: principiosActivos should be an array");
      return [];
    }
    const result = [];

    principiosActivos.forEach((principioActivo) => {
      if (principioActivo && Array.isArray(principioActivo.presentacion)) {
        principioActivo.presentacion.forEach((presentacion) => {
          presentacion.composicion.forEach((composicion) => {
            result.push({
              value: `${principioActivo.nombre} @ ${presentacion.nombre} @ ${composicion}`,
              label: `${principioActivo.nombre} - ${presentacion.nombre} - ${composicion}`,
            });
          });
        });
      } else {
        console.warn(
          `Skipping invalid principle active data: ${JSON.stringify(
            principioActivo
          )}`
        );
      }
    });

    // Ordenar el resultado por label
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  // Usage
  const SingleOptionsPrincipioActivo = generateSingleOptionsPrincipioActivo(
    filteredPrincipiosActivos
  );
  //console.log("SingleOptionsPrincipioActivo:", SingleOptionsPrincipioActivo);

  function handleSelectPrincipioActivo(selectedPrincipioActivo) {
    setSelectedPrincipioActivo(selectedPrincipioActivo);
  }

  function handleDiasChange(value, index) {
    const newPrincipiosActivos = [...listaPrincipiosActivos];
    newPrincipiosActivos[index].dias = value;
    setListaPrincipiosActivos(newPrincipiosActivos);
    removeDivTabla();
  }

  function handleDosisChange(value, index) {
    const newPrincipiosActivos = [...listaPrincipiosActivos];
    newPrincipiosActivos[index].dosis = value;
    setListaPrincipiosActivos(newPrincipiosActivos);
    removeDivTabla();
  }

  function handleSelectPrincipioActivo(selectedOption, index) {
    const newPrincipiosActivos = [...listaPrincipiosActivos];
    newPrincipiosActivos[index].principioActivo = selectedOption;
    setListaPrincipiosActivos(newPrincipiosActivos);
    removeDivTabla();
  }

  function removeDivTabla() {
    // Eliminar el div con id farme__divTabla_hijo
    const tablaDiv = document.getElementById("farme__divTabla_hijo");
    if (tablaDiv) {
      tablaDiv.remove();
    }

    // Mostrar el botón de búsqueda
    setShowSearchButton(true);

    //ocultar boton crear cotillon
    setShowCotillonButton(false);
  }
  function addRow() {
    setListaPrincipiosActivos([
      ...listaPrincipiosActivos,
      { principioActivo: null, dias: "", dosis: "" },
    ]);

    removeDivTabla();
  }

  function removeRow(index) {
    const newPrincipiosActivos = listaPrincipiosActivos.filter(
      (_, i) => i !== index
    );
    setListaPrincipiosActivos(newPrincipiosActivos);

    const tablaDiv = document.getElementById("farme__divTabla_hijo");
    if (tablaDiv) {
      tablaDiv.remove();
    }
    //ocultar boton crear cotillon
    setShowCotillonButton(false);
  }
  function showdetail() {
    setShowdetails(false);
  }

  //const [principiosActivosFinales, setPrincipiosActivosFinales] = useState([]);

  const actualizarTabla = (comparacion) => {
    const tablaHtml = generarTablaComparacion(comparacion);
    const divTabla = document.getElementById("farme__divTabla");
    if (divTabla) {
      divTabla.innerHTML = tablaHtml;
    }
  };

  //PROCESAR FORMULARIO
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar formulario y armar solicitud de medicamentos
    const newFormattedPrincipios = listaPrincipiosActivos
      .map((item) => {
        const valueArray = item.principioActivo.value.split("@");

        if (valueArray.length !== 3) {
          console.error(
            "Invalid format for principle active:",
            item.principioActivo.value
          );
          return null;
        }

        return {
          nombre: valueArray[0].trim(),
          presentacion: {
            nombre: valueArray[1].trim(),
            composicion: valueArray[2].trim(),
          },
          dosis: parseInt(item.dosis, 10),
          tratamiento: parseInt(item.dias, 10),
          cantidad: parseInt(item.dosis, 10) * parseInt(item.dias, 10),
        };
      })
      .filter(Boolean);

    setFormattedPrincipios(newFormattedPrincipios);

    if (newFormattedPrincipios.length > 0) {
      const solicitar = {
        medicamentos: newFormattedPrincipios,
      };

      console.log("Solicitud de Medicamentos:", JSON.stringify(solicitar));

      try {
        // Dispatch la acción para obtener los medicamentos
        dispatch(listaMedicamentos(solicitar));
        // No necesitamos hacer nada más aquí, useEffect se encargará de actualizar la tabla
      } catch (error) {
        console.error("Error al solicitar medicamentos:", error);
        // Manejar el error (por ejemplo, mostrar un mensaje al usuario)
      }
    }
  };

  const handleCotillon = async () => {
    // Ocultar Lista de Principios Activos
    setShowListaPrincipiosActivos(false);

    let medicamentos = [];

    medicamentosFarmabien.forEach((medicamento) => {
      let prinActivos = {};

      prinActivos = {
        nombre: medicamento.nombre,
        presentacion: [
          {
            nombre: medicamento.presentacion.nombre,
            composicion: [medicamento.presentacion.composicion],
          },
        ],
        activo: true,
        creado: null,
        actualizado: null,
      };

      medicamento.opcionesLaboratorio.forEach((opcion) => {
        // Buscar el objeto laboratorio completo en laboratorios[0]
        const laboratorioCompleto = laboratorios[0].find(
          (lab) => lab.nombre === opcion.laboratorio
        );

        medicamentos.push({
          principioactivo: prinActivos,
          laboratorio: laboratorioCompleto,
          nombre: opcion.nombre_comercial,
          presentacion: medicamento.presentacion.nombre,
          composicion: medicamento.presentacion.composicion,
          cantidad: parseInt(opcion.cajasNecesarias, 10),
          precio: parseFloat(opcion.precioOriginal, 10),
          creado: null,
        });
      });
    });

    // Actualizar solicitud.medicamentos con el nuevo array medicamentos
    const nuevaSolicitud = {
      ...solicitud,
      porcentaje_avance: 50,
      medicamentos: medicamentos,
    };

    try {
      // Dispatch para actualizar solicitud con los medicamentos
      dispatch(editSolicitud(nuevaSolicitud));
      //activamos ver delivery
      setShowCamposInfo(true);
      //ocultar boton crear cotillon
      setShowCotillonButton(false);
    } catch (error) {
      console.error("Error al actualizar solicitud:", error);
      // Manejar el error (por ejemplo, mostrar un mensaje al usuario)
    }
  };

  /**
   * Compara los medicamentos solicitados con los disponibles en Farmabien.
   * @param {array} formattedPrincipios - Arreglo con los medicamentos solicitados
   * @param {array} resultado - Arreglo con los medicamentos disponibles en Farmabien
   * @returns {array} Arreglo con los resultados de la comparación
   */
  function compararMedicamentosSolicitados(formattedPrincipios, resultado) {
    // Añade esta comprobación al principio de la función
    if (!resultado || !Array.isArray(resultado)) {
      console.error(
        "resultado es nulo, indefinido o no es un array:",
        resultado
      );
      return formattedPrincipios.map((principio) => ({
        ...principio,
        estado: "Error",
        mensaje: "No se pudo obtener información de Farmabien.",
        cantidadDisponible: 0,
        costoTotal: 0,
        costoOriginal: 0,
        opcionesLaboratorio: [],
      }));
    }
    const resultadoComparacion = formattedPrincipios.map((principio) => {
      const encontrado = resultado.find(
        (processed) =>
          processed.farme.nombre === principio.nombre &&
          processed.farme.presentacion.nombre ===
            principio.presentacion.nombre &&
          processed.farme.presentacion.composicion ===
            principio.presentacion.composicion
      );

      if (!encontrado) {
        return {
          ...principio,
          estado: "No disponible",
          mensaje: "Este medicamento no está disponible en Farmabien.",
          cantidadDisponible: 0,
          costoTotal: 0,
          costoOriginal: 0,
          opcionesLaboratorio: [],
        };
      }

      const cantidadRequerida = principio.cantidad;
      const cantidadDisponible = encontrado.farmabien.reduce(
        (sum, med) => sum + parseInt(med.unidades * med.cajasNecesarias),
        0
      );

      const opcionesLaboratorio = encontrado.farmabien.map((med) => ({
        laboratorio: med.laboratorio,
        nombre_comercial: med.nombre_comercial,
        unidades: med.unidades,
        disponible: med.disponible,
        precio: parseFloat(med.precio),
        precioOriginal: parseFloat(med.precioOriginal),
        cajasNecesarias: med.cajasNecesarias,
      }));

      const costoTotal = parseFloat(encontrado.costoTotal);
      const costoOriginal = parseFloat(encontrado.costoOriginal);

      let estado, mensaje;
      if (cantidadDisponible >= cantidadRequerida) {
        estado = "Disponible";
        mensaje = "Este medicamento está completamente disponible.";
      } else if (cantidadDisponible > 0) {
        estado = "Parcialmente disponible";
        mensaje = `Solo se puede proporcionar ${cantidadDisponible} unidades de las ${cantidadRequerida} solicitadas. Desglose por laboratorio:`;
        opcionesLaboratorio.forEach((opcion) => {
          if (opcion.cajasNecesarias > 0) {
            mensaje += `\n- ${opcion.laboratorio}: ${
              opcion.unidades * opcion.cajasNecesarias
            } unidades (${opcion.cajasNecesarias} cajas)`;
          }
        });
      } else {
        estado = "No disponible";
        mensaje = "Este medicamento no está disponible en ningún laboratorio.";
      }

      return {
        ...principio,
        estado,
        mensaje,
        cantidadDisponible,
        costoTotal,
        costoOriginal,
        opcionesLaboratorio,
      };
    });

    return resultadoComparacion;
  }

  /**
   * Genera la tabla de comparación de los medicamentos solicitados
   * contra los disponibles en la farmacia.
   * @param {Object[]} resultadoComparacion - Arreglo de objetos con los resultados de la comparación.
   * Cada objeto tiene las siguientes propiedades:
   * - nombre: string - Nombre del medicamento.
   * - presentacion: Object - Presentación del medicamento (nombre, composición, etc.).
   * - cantidad: number - Cantidad de unidades solicitadas.
   * - cantidadDisponible: number - Cantidad de unidades disponibles en la farmacia.
   * - costoOriginal: number - Costo original del medicamento.
   * - opcionesLaboratorio: Object[] - Arreglo de objetos con las opciones de laboratorio
   * disponibles para cada medicamento.
   * - estado: string - Estado del medicamento (Disponible, Parcialmente disponible, No disponible).
   * @returns {string} - Código HTML de la tabla de comparación.
   */
  function generarTablaComparacion(resultadoComparacion) {
    let costoTotal = 0;
    let mostrarBoton = true;
    let html =
      '<div id="farme__divTabla_hijo"><h5 class="fw-semibold text-uppercase mb-3">Lista de Medicamentos Encontrados</h5><div class="table-responsive"><table class="table table-striped" border="1" cellpadding="5" cellspacing="0">';
    html += "<thead><tr>";
    html +=
      "<th scope='col'>Nombre</th><th>Presentación</th><th>Unidades Requeridas</th>";
    html += "<th scope='col'>Unidades Disponibles</th><th>Costo ($)</th>";
    //html += "<th>Opciones de Laboratorio</th><th>Acciones</th>";
    html += "<th scope='col'>Opciones de Laboratorio</th>";
    html += "<th scope='col'>Resultado</th>";
    html += "</tr></thead><tbody>";

    resultadoComparacion.forEach((principio, index) => {
      costoTotal += parseFloat(principio.costoOriginal);
      html += "<tr>";
      html += `<td>${principio.nombre}</td>`;
      html += `<td>${principio.presentacion.nombre} ${principio.presentacion.composicion}</td>`;
      html += `<td>${principio.cantidad}</td>`;

      html += `<td>${principio.cantidadDisponible}</td>`;
      html += `<td>${principio.costoOriginal}</td>`;
      html += "<td>";
      html += principio.opcionesLaboratorio
        .map(
          (opcion) =>
            `<div><strong>${opcion.laboratorio}</strong>: ${opcion.nombre_comercial}, ${opcion.unidades} unidades, ${opcion.disponible} disponibles, Precio ($): ${opcion.precioOriginal}, Cant. Cajas: ${opcion.cajasNecesarias}</div>`
        )
        .join("<br>");
      html += "</td>";
      html += `<td>
                ${
                  principio.estado === "Disponible"
                    ? `<span class="badge bg-success-subtle text-success text-uppercase">
                      ${principio.estado}
                    </span>`
                    : `<span class="badge bg-danger-subtle text-danger text-uppercase">
                      ${principio.estado}
                    </span>`
                }
              </td>`;

      // Agregar botón "Agregar Faltante" si el estado no es "Disponible"
      if (principio.estado !== "Disponible") {
        mostrarBoton = false;
      }

      html += "</tr>";
    });

    html += `</tbody></table></div><div class="mt-3 p-3 text-center ${
      costoTotal > parseFloat(solicitud.montoCobertura)
        ? "bg-danger-subtle"
        : "bg-success-subtle"
    } "><strong>Costo Total del Cotillon </strong>: $${costoTotal} - <strong>Cobertura Disponible ($)</strong>: $${solicitud.montoCobertura.toFixed(
      2
    )}</div></div>`;

    //console.log( mostrarBoton, costoTotal, parseFloat(solicitud.montoCobertura),  parseFloat(solicitud.montoCobertura) >= costoTotal) ;

    if (mostrarBoton && parseFloat(solicitud.montoCobertura) >= costoTotal) {
      setShowCotillonButton(true);
    } else {
      setShowCotillonButton(false);
    }

    return html;
  }

  function verCotillon() {
    let costoTotal = 0;
    let html =
      '<div id="farme__divTabla_hijo"><div class="table-responsive"><table class="table table-striped" border="1" cellpadding="5" cellspacing="0">';
    html += "<thead><tr>";
    html += "<th scope='col'>Nombre</th><th>Presentación</th>";
    html += "<th>Laboratorio</th>";
    html += "<th>Cantidad</th>";
    html += "<th>Costo ($)</th>";
    html += "</tr></thead><tbody>";

    solicitud.medicamentos.forEach((medicamento, index) => {
      costoTotal += parseFloat(medicamento.precio);
      html += "<tr>";
      html += `<td>${medicamento.nombre}</td>`;
      html += `<td>${medicamento.principioactivo.presentacion[0].nombre} - ${medicamento.principioactivo.presentacion[0].composicion[0]}</td>`;
      html += `<td>${medicamento.laboratorio.nombre}</td>`;
      html += `<td>${medicamento.cantidad}</td>`;
      html += `<td>${medicamento.precio}</td>`;
      html += "</tr>";
    });

    html += `</tbody></table></div><div class="mt-3 p-3 text-center ${
      costoTotal > parseFloat(solicitud.montoCobertura)
        ? "bg-danger-subtle"
        : "bg-success-subtle"
    } "><strong>Costo Total del Cotillon </strong>: $${costoTotal} - <strong>Cobertura Disponible ($)</strong>: $${solicitud.montoCobertura.toFixed(
      2
    )}</div></div>`;

    return html;
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardBody>
              {!(solicitud.medicamentos && solicitud.medicamentos.length > 0) &&
                showListaPrincipiosActivos && (
                  <Form
                    id="principios-activos-form"
                    onSubmit={handleSubmit}
                    className="form-steps"
                  >
                    <div id="farme__lista_principios_activos">
                      {listaPrincipiosActivos.map((principioActivo, index) => (
                        <Row
                          className={`p-3 mb-3 align-items-center row-${index}`}
                          key={index}
                        >
                          <Col lg={6} xs={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor={`principio-activo-input-${index}`}
                              >
                                Principio Activo{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                value={principioActivo.principioActivo}
                                onChange={(selectedOption) =>
                                  handleSelectPrincipioActivo(
                                    selectedOption,
                                    index
                                  )
                                }
                                options={SingleOptionsPrincipioActivo}
                                required
                              />
                            </div>
                          </Col>
                          <Col lg={2} xs={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor={`dias-input-${index}`}
                              >
                                Tratamiento{" "}
                                <span className="text-danger">*</span>
                              </Label>

                              <Input
                                type="number"
                                max={30}
                                min={1}
                                className="form-control"
                                id={`tratamiento-input-${index}`}
                                placeholder="Por cuántos días"
                                value={principioActivo.dias}
                                onChange={(e) =>
                                  handleDiasChange(e.target.value, index)
                                }
                                required
                              />
                            </div>
                          </Col>
                          <Col lg={2} xs={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor={`gen-info-password-input-${index}`}
                              >
                                Dosis <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                max={50}
                                min={1}
                                className="form-control"
                                id={`dosis-input-${index}`}
                                placeholder="Por dia"
                                value={principioActivo.dosis}
                                onChange={(e) =>
                                  handleDosisChange(e.target.value, index)
                                }
                                required
                              />
                            </div>
                          </Col>
                          <Col lg={2} xs={4}>
                            <Button
                              color="danger"
                              size="sm"
                              className="btn-icon"
                              onClick={() => removeRow(index)}
                            >
                              {" "}
                              <i className="ri-delete-bin-5-line " />{" "}
                            </Button>
                          </Col>
                        </Row>
                      ))}

                      <Row className="align-items-center">
                        <Col lg={12}>
                          <div className="mb-3 text-center">
                            <Button
                              color="secondary"
                              outline
                              className="btn-border"
                              onClick={addRow}
                            >
                              <span className="icon-on">
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                Medicamentos
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-end">
                        <Col lg={12}>
                          <div className="mb-3 text-end">
                            {showSearchButton && (
                              <button
                                id="farme__boton__buscar"
                                type="submit"
                                className="btn btn-success btn-label right ms-auto nexttab nexttab"
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                Buscar Medicamentos
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}

              <div id="farme__divTabla"></div>

              <Row className="align-items-end mt-3">
                <Col lg={12}>
                  <div className="mb-3 text-end">
                    {!(
                      solicitud.medicamentos &&
                      solicitud.medicamentos.length > 0
                    ) &&
                      showCotillonButton && (
                        <button
                          id="farme__boton__cotillon"
                          type="submit"
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                          onClick={handleCotillon}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Crear Cotillon
                        </button>
                      )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SolicitudMedicamentos;
