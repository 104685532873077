import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";

import {
  getSolicitudesEntregar,
  resetSolicitudFlag,
  editSolicitud,
  createRutaOptima,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Lista } from "./Lista";

const Listar = () => {
  document.title = "Lista de Delivery | Farme";

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [solicitudesEntregar, setSolicitudesEntregar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectLayoutState = (state) => state;
  const pageData = createSelector(selectLayoutState, (state) => ({
    solicitudes: state.Solicitudes.solicitudes,
    error: state.Solicitudes.error,
    loading: state.Solicitudes.loading,
    errorMsg: state.Solicitudes.errorMsg,
    colorMsg: state.Solicitudes.colorMsg,
    exito: state.Solicitudes.exito,
  }));
  const { solicitudes, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  const ruta = useSelector((state) => state.Solicitudes.ruta);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetSolicitudFlag());
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem("authUser")) {
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        dispatch(getSolicitudesEntregar(obj.id));
      }
    }, 2000);
  }, [dispatch, colorMsg]);

  useEffect(() => {
    if (solicitudes) {
      setIsLoading(false);
    }
  }, [solicitudes]);

  const [listas, setListas] = useState([]);

  useEffect(() => {
    if (solicitudesEntregar && solicitudes) {
      const nuevasListas = solicitudes[0].map((solicitud) => solicitud.id);
      setListas(nuevasListas);
      // Resetea solicitudesEntregar para futuros clicks
      setSolicitudesEntregar(false);
    }
  }, [solicitudesEntregar, solicitudes]);

  useEffect(() => {
    console.log("Lista actualizada de IDs:", listas);
    if (listas.length > 0) {
      //console.log("Dispatching createRutaOptima with:", listas);
      dispatch(createRutaOptima(listas));
    }
  }, [listas, dispatch]);

  // Añade otro useEffect para loguear la ruta cuando cambie
  useEffect(() => {
    console.log("Ruta actualizada:", ruta);
    if (ruta && ruta.length > 0) {
      setIsModalOpen(true);
    }
  }, [ruta]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <React.Fragment>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>
          <h5 className="modal-title">Ruta Óptima</h5>
          <Button
            type="button"
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>

        <ModalBody>
          {renderMap(ruta)}

          <GoogleMapsButton ruta={ruta} />
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                      Delivery
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap"></div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Lista
                    solicitudes={solicitudes[0] || []}
                    error={error ? error.message : null}
                    errorMsg={errorMsg}
                    colorMsg={colorMsg}
                    setSolicitudesEntregar={setSolicitudesEntregar}
                    setIsLoading={setIsLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const renderMap = (ruta) => {
  if (!ruta || ruta.length === 0) return null;

  const center = { lat: ruta[0].coordenadas[0], lng: ruta[0].coordenadas[1] }; // Usando el primer punto como centro
  const path = ruta.map((point) => ({
    lat: point.coordenadas[0],
    lng: point.coordenadas[1],
  }));
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_APIKEYGOOGLEMAPS;

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={center}
        zoom={12}
      >
        <Polyline
          path={path}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
        {ruta.map((point, index) => (
          <Marker
            key={point.id_solicitud}
            position={{ lat: point.coordenadas[0], lng: point.coordenadas[1] }}
            label={`${index + 1}`}
            title={point.direccion}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

const generateGoogleMapsLink = (ruta) => {
  if (!ruta || ruta.length === 0) return "";

  const baseUrl = "https://www.google.com/maps/dir/?api=1";
  const origin = `${ruta[0].coordenadas[0]},${ruta[0].coordenadas[1]}`;
  const destination = `${ruta[ruta.length - 1].coordenadas[0]},${
    ruta[ruta.length - 1].coordenadas[1]
  }`;

  let waypoints = "";
  if (ruta.length > 2) {
    waypoints = ruta
      .slice(1, -1)
      .map((point) => `${point.coordenadas[0]},${point.coordenadas[1]}`)
      .join("|");
  }

  return `${baseUrl}&origin=${origin}&destination=${destination}&waypoints=${waypoints}&travelmode=driving`;
};

const GoogleMapsButton = ({ ruta }) => {
  const handleClick = () => {
    const link = generateGoogleMapsLink(ruta);
    window.open(link, "_blank");
  };

  return (
    <Button className="btn btn-primary mt-3" onClick={handleClick}>
      Ver en Google Maps
    </Button>
  );
};

export default Listar;
