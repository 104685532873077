import React, { useEffect, useState } from "react";
import {
  editPlantilla,
  getLabs,
  getPrincActivos,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Input, Label, Form, Spinner, Button } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";

const Editar = ({ planilla }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLabs());
    dispatch(getPrincActivos());
  }, [dispatch]);

  const opcionesPresentacion = ["A", "B", "C"];
  const opcionesFarmacologica = ["A", "B", "C", "D"];
  const optionsBoolean = [
    { value: "true", label: "Si" },
    { value: "false", label: "No" },
  ];

  // Obtener los laboratorios
  const selectLabsState = (state) => state.Laboratorios;
  const labsPageData = createSelector(selectLabsState, (state) => state);
  // Inside your component
  const { laboratorios } = useSelector(labsPageData);

  console.log("laboratorios:", laboratorios[0]);

  // Obtener los principios activos
  const selectPrincActState = (state) => state.PrincipiosActivos;
  const princActPageData = createSelector(
    selectPrincActState,
    (state) => state
  );
  // Inside your component
  const { principiosActivos } = useSelector(princActPageData);

  console.log("principiosActivos:", principiosActivos[0]);

  //Atributos del Modelo
  const [nombre, setNombre] = useState(planilla.nombre);
  const [laboratoriosNuevos, setLaboratoriosNuevos] = useState(
    planilla.laboratorios
  );
  const [principiosactivosNuevos, setPrincipiosactivosNuevos] = useState(
    planilla.principiosactivos
  );
  const [activo, setActivo] = useState(planilla.activo);

  const [calidadFarmacologica, setCalidadFarmacologica] = useState([
    ["A", planilla.calidadFarmacologica[0][1]],
    ["B", planilla.calidadFarmacologica[1][1]],
    ["C", planilla.calidadFarmacologica[2][1]],
    ["D", planilla.calidadFarmacologica[3][1]],
  ]);

  console.log("calidadFarmacologica:", calidadFarmacologica);

  const [calificacionImagen, setCalificacionImagen] = useState([
    ["A", planilla.calificacionImagen[0][1]],
    ["B", planilla.calificacionImagen[1][1]],
    ["C", planilla.calificacionImagen[2][1]],
  ]);

  console.log("calificacionImagen:", calificacionImagen);

  const [activoOptionSelect, setActivoOptionSelect] = useState(
    activo ? optionsBoolean[0] : optionsBoolean[1]
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [dispatch, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const update = {
      id: planilla.id,
      nombre: nombre.trim(),
      laboratorios: laboratoriosNuevos,
      principiosactivos: principiosactivosNuevos,
      calidadFarmacologica: calidadFarmacologica,
      calificacionImagen: calificacionImagen,
      activo: activo,
      creado: planilla.creado,
      actualizado: null,
    };

    console.log("update:", update);

    dispatch(editPlantilla(update));
    setLoading(true);
  };

  //  OBTENER LAS OPCIONES DE LABORATORIOS Y PRINCIPIOS ACTIVOS
  const getLaboratorioOptions = () => {
    return laboratorios[0]?.map((laboratorio) => ({
      value: laboratorio,
      label: laboratorio.nombre,
    }));
  };

  // OBTENER LAS OPCIONES DE PRINCIPIOS
  const getPrincipiosOptions = () => {
    return principiosActivos[0]?.map((principio) => ({
      value: principio,
      label: principio.nombre,
    }));
  };

  /**
   * Actualiza el estado de laboratoriosNuevos con los valores seleccionados.
   * @param {object[]} selectedOptions - Un array de objetos con las opciones seleccionadas.
   */
  function handleLaboratoriosChange(selectedOptions) {
    setLaboratoriosNuevos(selectedOptions.map((option) => option.value));
  }

  /**
   * Actualiza el estado de principiosactivosNuevos con los valores seleccionados.
   * @param {object[]} selectedOptions - Un array de objetos con las opciones seleccionadas.
   */
  function handlePrincipiosActivosChange(selectedOptions) {
    setPrincipiosactivosNuevos(selectedOptions.map((option) => option.value));
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row lg={12} className="p-3">
          <Col xxl={6} md={6}>
            <div>
              <Label className="form-label" htmlFor="nombre">
                Nombre <span className="text-danger">*</span>
              </Label>
            </div>
            <Input
              required
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
              id="nombre"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Col>

          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="activo">
                Activo <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={activoOptionSelect}
                onChange={(selectedOption) => {
                  setActivo(selectedOption.value === "true");
                }}
                options={[
                  { value: "", label: "Seleccione una opción" },
                  { value: "true", label: "Si" },
                  { value: "false", label: "No" },
                ]}
                required
                id="activo"
                className="js-example-basic-single mb-0"
                name="activo"
              />
            </div>
          </Col>
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="activo">
                Laboratorios No Admitidos <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={planilla.laboratorios.map((laboratorio) => ({
                  value: laboratorio,
                  label: laboratorio.nombre,
                }))}
                isMulti={true}
                isClearable={true}
                classNamePrefix="farme"
                options={getLaboratorioOptions()}
                required
                id="laboratorios"
                className="js-example-basic-single mb-0"
                name="laboratorios"
                onChange={handleLaboratoriosChange}
              />
            </div>
          </Col>
          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="activo">
                Principios Activos No Admitidos
                <span className="text-danger">*</span>
              </Label>
              <Select
                defaultValue={planilla.principiosactivos.map(
                  (principioactivo) => ({
                    value: principioactivo,
                    label: principioactivo.nombre,
                  })
                )}
                isMulti={true}
                isClearable={true}
                classNamePrefix="farme"
                options={getPrincipiosOptions()}
                required
                id="principiosactivos"
                className="js-example-basic-single mb-0"
                name="principiosactivos"
                onChange={handlePrincipiosActivosChange}
              />
            </div>
          </Col>

          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="calidadFarmacologica">
                Calidad Farmacológica
              </Label>
              {calidadFarmacologica.map((item, index) => (
                <Input
                  type="number"
                  className="form-control mb-2"
                  id={`calidadFarmacologica-${index}`}
                  name={`calidadFarmacologica[${index}]`}
                  placeholder={`${item[0]}`}
                  value={item[1]}
                  onChange={(e) =>
                    setCalidadFarmacologica((prevState) =>
                      prevState.map((item, i) =>
                        i === index ? [item[0], parseInt(e.target.value)] : item
                      )
                    )
                  }
                />
              ))}
            </div>
          </Col>

          <Col xxl={6} md={6}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="calificacionImagen">
                Calificacion Imagen
              </Label>
              {calificacionImagen?.map((item, index) => (
                <Input
                  type="number"
                  className="form-control mb-2"
                  id={`calificacionImagen-${index}`}
                  name={`calificacionImagen[${index}]`}
                  placeholder={`${item[0]}`}
                  value={item[1]}
                  onChange={(e) =>
                    setCalificacionImagen((prevState) =>
                      prevState.map((item, i) =>
                        i === index ? [item[0], parseInt(e.target.value)] : item
                      )
                    )
                  }
                />
              ))}
            </div>
          </Col>
        </Row>

        <Row className="align-items-end">
          <Col lg={12}>
            <div className="mt-4 mb-3 text-end">
              <button
                type="submit"
                className="btn btn-success btn-label right ms-auto nexttab nexttab"
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                ) : null}
                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                Editar Planilla Estratégica
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Editar;
