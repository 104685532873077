//LOGIN FARME
export const POST_FAKE_JWT_LOGIN = "/auth/token";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
//LABORATORIOS FARME
export const BASE_LABORATORIOS = "/laboratorios";
//USUARIOS FARME
export const BASE_USUARIOS = "/usuarios";
//PRINCIPIOS ACTIVOS FRAME
export const BASE_PRINCIPIOSACTIVOS = "/principiosactivos";
//Servicio De Entrega
export const BASE_SERVICIODEENTREGAS = "/servicioentregas";
//Planilla Estrategica
export const BASE_PLANILLAESTRATEGICA = "/planillaestrategica";
//Solicitudes
export const BASE_SOLICITUDES = "/solicitud";



//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

