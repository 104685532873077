import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { editSolicitud } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Label,
  Button,
  Form,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
//import {  responsablesDelivery } from "../../../common/data";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

const Factura_Y_Delivery = ({
  hideButtons,
  setShowResponsable,
  setShowdetails,
  setShowCamposInfo,
  setBtnDely,
  usuarios,
  solicitud,
}) => {
  //console.log("Factura_Y_Delivery component is rendering");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (solicitud) {
      setIsLoading(false);
    }
  }, [solicitud]);

  const [emailDelivery, setEmailDelivery] = useState("");
  const [files, setFiles] = useState([]);

  const [showDelivery, setShowDelivery] = useState(null);


  useEffect(() => {   
    setShowDelivery(setShowCamposInfo);
  }, [setShowCamposInfo]);

  const [btnFactura, setBtnFactura] = useState(false);
  //Modal factura
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [facturaImageUrl, setFacturaImageUrl] = useState("");

  const FacturaModal = ({ isOpen, toggle, imageUrl }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Factura</ModalHeader>
        <ModalBody>
          <img src={imageUrl} alt="Factura" style={{ width: "100%" }} />
        </ModalBody>
      </Modal>
    );
  };

  const openFacturaModal = (imageUrl) => {
    setFacturaImageUrl(imageUrl);
    setModalIsOpen(true);
  };

  const [fechaFacturacion, setFechaFacturacion] = useState(null);
  function handleFechaFacturacion(e) {
    setFechaFacturacion(e.target.value);
  }

  const [numeroFactura, setNumeroFactura] = useState(null);
  function handleNumeroFactura(e) {
    setNumeroFactura(e.target.value);
  }

  const [montoTotal, setMontoTotal] = useState(null);
  function handleMontoTotal(e) {
    const value = parseFloat(e.target.value);
    setMontoTotal(isNaN(value) ? null : value);
  }

  function crearFechaHora(fecha, hora) {
    // Asumiendo que fecha está en formato 'YYYY-MM-DD' y hora en formato 'HH:mm'
    const [year, month, day] = fecha.split('-');
    const [hours, minutes] = hora.split(':');
    
    // Crear la cadena ISO manualmente
    const fechaHoraFormateada = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;

    return fechaHoraFormateada;
  }

  function handleSubmitDelivery() {
    const fechaEntrega = crearFechaHora(fecha, hora);

    //console.log("fechaEntrega Delivery:", fechaEntrega);

    let delivery = {
      servicioEntrega: responsable,
      estatus: "Por Entregar",
      fecha: fechaEntrega
    
    };
    // Actualizar solicitud.delivery
    const nuevaSolicitud = {
      ...solicitud,
      porcentaje_avance: 75,
      estatus: "Por Entregar",
      delivery: delivery,
    };


    //console.log("nuevaSolicitud:", (nuevaSolicitud));

    try {
      //console.log("nuevaSolicitud:", JSON.stringify(nuevaSolicitud));
      // Dispatch para actualizar solicitud con los medicamentos
      dispatch(editSolicitud(nuevaSolicitud));

      setViewDelivery(false); //ver registro del delivery
      setViewFacturacion(true); //ver card de facturacion
    } catch (error) {
      console.error("Error al actualizar solicitud:", error);
      // Manejar el error (por ejemplo, mostrar un mensaje al usuario)
    }
  }

  function handleSubmitFacturaion() {
    const fechaEntrega = crearFechaHora(fecha, hora);

    let archivoAdjunto = null;

    if (files.length > 0) {
      const file = files[0];
      archivoAdjunto = file.getFileEncodeDataURL();
    }

    let facturacion = {
      fecha: fechaFacturacion,
      numeroFactura: numeroFactura,
      montoTotal: montoTotal,
      adjunto: archivoAdjunto,
    };
    // Actualizar solicitud.delivery
    const nuevaSolicitud = {
      ...solicitud,
      porcentaje_avance: 95,
      facturacion: facturacion,
    };

    try {

      // Dispatch para actualizar solicitud con los medicamentos
      dispatch(editSolicitud(nuevaSolicitud));
    } catch (error) {
      console.error("Error al actualizar solicitud:", error);
      // Manejar el error (por ejemplo, mostrar un mensaje al usuario)
    }
  }

  function showfactura() {
    setShowCamposInfo(false);
    setShowResponsable(true);
    setBtnFactura(true);

  }
  function showdetail() {
    setShowdetails(true);
  }

  const [responsable, setResposnsable] = useState("");

  function handleResponsale(e) {
    usuarios[0].forEach((servicio) => {
      if (servicio.id === e.target.value) {
        setResposnsable(servicio);
        //console.log("responsable:", JSON.stringify(servicio));
      }
    });
  }

  const [fecha, setFecha] = useState("");

  function handleFecha(e) {
    setFecha(e.target.value);
    //console.log("fecha:", e.target.value);
  }

  const [hora, setHora] = useState("");

  function handleHora(e) {
    setHora(e.target.value);
    //console.log("hora:", e.target.value);
  }

  const [viewDelivery, setViewDelivery] = useState(false);
  //const [viewRegistroFacturacion, setViewRegistroFacturacion] = useState(false);

  useEffect(() => {
    //console.log("Cambio Solicitud UseEffect delivery");

    if (!isLoading && solicitud) {
      if (solicitud.delivery) {
        //console.log("Delivery registrado");
        const tablaHtml = verDeliveryInfo();
        //console.log("tablaHtml:", tablaHtml);
        const divTabla = document.getElementById("farme__divTabla__delivery");
        if (divTabla) {
          divTabla.innerHTML = tablaHtml;
          // Render the React component into the container
          ReactDOM.render(
            renderEditButton(),
            document.getElementById("edit-button-container")
          );
        }
        //setBtnDely(true);
        //setViewDelivery(true);

        setViewFacturacion(true);
      } else {
        console.log("No hay delivery registrado");

        removeDivTabla();
        setViewDelivery(false);
        setViewFacturacion(false);
      }
    }
  }, [solicitud, isLoading, dispatch]);

  const renderEditButton = () => {
    return (
      <Button onClick={editarSolicitudDelivery}>
        {" "}
        <i className="mdi mdi-pencil font-size-18" id="edittooltip" />{" "}
      </Button>
    );
  };

  function verDeliveryInfo() {
    let html =
      '<div id="farme__divTabla_hijo__delivery"><div class="table-responsive"><table class="table table-striped" border="1" cellpadding="5" cellspacing="0">';
    html += "<thead><tr>";
    html += "<th scope='col'>Responsable</th><th>Telefoono</th><th>Correo</th>";
    html += "<th>Estatus</th>";
    html += "<th>Fecha - Hora</th>";
    html += "<th>Accion</th>";
    html += "</tr></thead><tbody>";

    html += "<tr>";
    html += `<td>${solicitud.delivery.servicioEntrega.nombre} ${solicitud.delivery.servicioEntrega.apellido} </td>`;
    html += `<td>${solicitud.delivery.servicioEntrega.telefono}</td>`;
    html += `<td>${solicitud.delivery.servicioEntrega.correo}</td>`;
    html += `<td>${solicitud.delivery.estatus}</td>`;
    html += `<td>${solicitud.delivery.fecha}</td>`;
    html += `<td id="edit-button-container"></td>`;
    html += "</tr>";

    html += `</tbody></table></div></div>`;

    return html;
  }

  const [viewFacturacion, setViewFacturacion] = useState(false);
  const [viewRegistroFacturacion, setViewRegistroFacturacion] = useState(false);


  function verFacturacionInfo() {
    let html =
      '<div id="farme__divTabla_hijo__facturacion"><div class="table-responsive"><table class="table table-striped" border="1" cellpadding="5" cellspacing="0">';
    html += "<thead><tr>";
    html += "<th scope='col'>Fecha</th><th>Número de Factura</th>";
    html += "<th>Total ($) </th>";
    html += "<th>Factura</th>";
    html += "</tr></thead><tbody>";

    html += "<tr>";
    html += `<td>${solicitud.facturacion.fecha}  </td>`;
    html += `<td>${solicitud.facturacion.numeroFactura}</td>`;
    html += `<td>${solicitud.facturacion.montoTotal}</td>`;
    html += `<td><a href="#" onClick="window.openFacturaModal('${solicitud.facturacion.adjunto}')">Ver Factura</a></td>`;
    html += "</tr>";

    html += `</tbody></table></div></div>`;

    return html;
  }

  function removeDivTablaFacturacion() {
    // Eliminar el div con id farme__divTabla_hijo
    const tablaDiv = document.getElementById(
      "farme__divTabla_hijo__facturacion"
    );
    if (tablaDiv) {
      tablaDiv.remove();
    }
  }

  useEffect(() => {
    //console.log("Cambio Facturacion");

    if (!isLoading && solicitud) {
      if (solicitud.facturacion) {
        //console.log("Facturacion registrada");
        const tablaHtml = verFacturacionInfo();

        //console.log("tablaHtml:", tablaHtml);
        const divTabla = document.getElementById(
          "farme__divTabla__facturacion"
        );
        //console.log("divTabla", divTabla);

        if (divTabla) {
          divTabla.innerHTML = tablaHtml;
        }

        setViewFacturacion(true);

      } else {

        setViewFacturacion(false);

        //setViewRegistroFacturacion(false);
        
        removeDivTablaFacturacion();
      }
    }
  }, [solicitud, isLoading, dispatch]);

 

  function removeDivTabla() {
    // Eliminar el div con id farme__divTabla_hijo
    const tablaDiv = document.getElementById("farme__divTabla_hijo__delivery");
    if (tablaDiv) {
      tablaDiv.remove();
    }
  }



/*   console.log("showDelivery", showDelivery, "viewDelivery", viewDelivery);
  console.log("viewFacturacion", viewFacturacion);
  console.log("viewRegistroFacturacion", viewRegistroFacturacion);

  console.log("Solicitud:", solicitud); */

  window.openFacturaModal = openFacturaModal;

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  const editarSolicitudDelivery = () => {
    //console.log("Editar Solicitud");
    setViewDelivery(true);
    removeDivTabla();
  };

  return (
    <React.Fragment>
      {showDelivery && (
        <Card>
          <CardBody>
            <Row className="mt-3">
              <Col>
                <h6 className="fw-semibold text-uppercase">
                  INFORMACIÓN DELIVERY
                </h6>
              </Col>
            </Row>

            <div id="farme__divTabla__delivery"> </div>

            {solicitud.delivery && !viewDelivery ? (
              null
            ) : (
              <Form id="delivery-parte1-form" className="form-steps">
                <Row className="p-3">
                  <Col>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-responsable-input"
                      >
                        Responsable
                      </Label>
                      <select
                        className="form-select"
                        id="t-status"
                        data-choices
                        data-choices-search-false
                        aria-label="Default select"
                        required
                        onChange={(e) => {
                          handleResponsale(e);
                        }}
                      >
                        <option value="">Seleccione</option>
                        {usuarios &&
                          usuarios[0]
                            .filter((persona) => persona.activo === true && persona.perfil === "Delivery")
                            .map((persona) => (
                              <option key={persona.id} value={persona.id}>
                                {persona.nombre} {persona.apellido}
                              </option>
                            ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-fecha-input"
                      >
                        Fercha para la entrega
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        required
                        onChange={(e) => {
                          handleFecha(e);
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-hora-input"
                      >
                        Hora de la entrega
                      </Label>
                      <Input
                        required
                        type="time"
                        className="form-control"
                        id="exampleInputtime"
                        onChange={(e) => {
                          handleHora(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="mb-3 mt-4 text-end">
                      {!hideButtons && (
                        <Button
                          color="success"
                          className="btn-label right"
                          onClick={() => {
                            handleSubmitDelivery();
                          }}
                        >
                          {" "}
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                          Registrar Delivery{" "}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </CardBody>
        </Card>
      )}


        <Card>
          <CardBody>
            <Row className="mt-3">
              <Col>
                <h6 className="fw-semibold text-uppercase">
                  INFORMACIÓN FACTURACION
                </h6>
              </Col>
            </Row>

            <div id="farme__divTabla__facturacion"> </div>

            {solicitud.facturacion ? (
              null
            ) : (
              <Form id="facturacion-form" className="form-steps">
                <Row lg={12} className="p-3">
                  <Col lg={3}>
                    <div>
                      <Label
                        className="form-label"
                        htmlFor="gen-info-email-input"
                      >
                        Fecha
                      </Label>
                    </div>
                    <Input
                      type="date"
                      className="form-control"
                      id="FechaFacturacion"
                      onChange={(e) => {
                        handleFechaFacturacion(e);
                      }}
                    />
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-email-input"
                      >
                        Moneda
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="moneda"
                        value={"DÓLAR"}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-email-input"
                      >
                        Número de Factura
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="factura"
                        onChange={(e) => {
                          handleNumeroFactura(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-email-input"
                      >
                        Costo Total
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="total"
                        onChange={(e) => {
                          handleMontoTotal(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="gen-info-email-input"
                      >
                        Adjuntar Factura
                      </Label>
                      <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={false}
                        maxFiles={1}
                        name="files"
                        required={true}
                        labelIdle='Arrastra tus archivos o <span class="filepond--label-action">buscar en tu equipo</span>'
                        className="filepond filepond-input-multiple"
                        allowFileEncode={true}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-3 mt-4 text-end">
                      {!btnFactura && (
                        <Button
                          color="success"
                          className="btn-label right"
                          onClick={handleSubmitFacturaion}
                        >
                          {" "}
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Registrar Facturación
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </CardBody>
        </Card>
      

      <FacturaModal
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(false)}
        imageUrl={facturaImageUrl}
      />
    </React.Fragment>
  );
};

export default Factura_Y_Delivery;
