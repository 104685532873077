import React, { useState } from "react";
import { Card, CardBody, Col, Row, Label, Input } from "reactstrap";

import { datosSolicitudes } from "../../../common/data";

import SolicitudMedicamentos from "./SolicitudMedicamentos";
import ListaMedicamentos from "./ListaMedicamentos";
import Comentarios from "./Comentarios";
import Factura_Y_Delivery from "./Factura_Y_Delivery";
import { medicamentos } from "../../../helpers/backend_helper";

const TicketDescription = ({
  solicitud,
  showdetails,
  setShowdetails,
  setShowResponsable,
  setBtnDely,
  btnDely,
  usuarios,
}) => {
  const [hideButtons, setHideButtons] = useState(false);
  const [showCamposInfo, setShowCamposInfo] = useState(false);

  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card>
          <CardBody className="p-4">
            <h5 className="fw-semibold text-uppercase mb-3">
              Lista de Medicamentos
            </h5>

            <h6 className="fw-semibold text-uppercase mb-3">
              Por Principio Activos
            </h6>

            {showdetails && (
              <SolicitudMedicamentos
                solicitud={solicitud}
                setShowdetails={setShowdetails}
                setShowCamposInfo={setShowCamposInfo}
              />
            )}
          </CardBody>
        </Card>
        {showCamposInfo && (
          <>
            <Factura_Y_Delivery
              hideButtons={hideButtons}
              setShowResponsable={setShowResponsable}
              setShowdetails={setShowdetails}
              setShowCamposInfo={showCamposInfo}
              setBtnDely={setBtnDely}
              btnDely={btnDely}
              usuarios={usuarios}
              solicitud={solicitud}
            />
          </>
        )}
        <Card>
          <CardBody>
            <Comentarios solicitud={solicitud} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TicketDescription;
