import React, { useState, useEffect } from "react";
import { Col, Row, Input, Label, Form, Spinner, Alert } from "reactstrap";
import { createUser, resetUsuarioFlag } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import { Navigate } from "react-router-dom";

const CrearUsuario = () => {
  console.log("CrearUsuario rendered");
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const usuarioPageData = createSelector(selectLayoutState, (state) => ({
    usuario: state.Usuarios.usuario,
    error: state.Usuarios.error,
    loading: state.Usuarios.loading,
    errorMsg: state.Usuarios.errorMsg,
    colorMsg: state.Usuarios.colorMsg,
    exito: state.Usuarios.exito,
  }));

  const { usuario, exito, error, loading, errorMsg, colorMsg } =
    useSelector(usuarioPageData);

  console.log(
    "usuario:",
    usuario,
    "exito:",
    exito,
    "error:",
    error,
    "loading:",
    loading,
    "errorMsg:",
    errorMsg,
    "colorMsg:",
    colorMsg
  );

  useEffect(() => {
    console.log("useEffect de CrearUsuario se ejecut ");
    if (errorMsg) {
      console.log("errorMsg es true, se ejecuta setTimeout");
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        dispatch(resetUsuarioFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    console.log("useEffect de CrearUsuario para resetear flag se ejecut ");
    if (exito) {
      console.log("exito es true, se ejecuta setTimeout");
      setTimeout(() => {
        console.log("setTimeout se ejecut ");
        setRedireccionar(true);
      }, 3000);
    }
  }, [dispatch, exito]);

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [usuarioRol, setUsuarioRol] = useState("");
  const [activo, setActivo] = useState(false);
  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [perfil, setPerfil] = useState("");
  const [contrasena, setContrasena] = useState("");
  const opcionesPerfil = ["Administrador", "MyHealth", "Farme", "Delivery"];
  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(null);

  // Custom password validation function
  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("La contraseña debe tener al menos 8 caracteres.");
    }

    if (!/[a-z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra minúscula.");
    }

    if (!/[A-Z]/.test(password)) {
      errors.push("La contraseña debe contener al menos una letra mayúscula.");
    }

    if (!/\d/.test(password)) {
      errors.push("La contraseña debe contener al menos un número.");
    }

    if (!/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/.test(password)) {
      errors.push("La contraseña debe contener al menos un símbolo especial.");
    }

    return errors.length > 0 ? errors : null;
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setContrasena(newPassword);
    const validationErrors = validatePassword(newPassword);
    setPasswordError(validationErrors);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit se ejecut ");

    const usuarioCreado = {
      nombre: nombre.trim(),
      apellido: apellido.trim(),
      correo: correo,
      telefono: telefono.trim(),
      usuario: usuarioRol.trim(),
      contrasena: contrasena.trim(),
      perfil: perfil,
      activo: activo,
      creado: null,
      actualizado: null,
    };

    console.log("usuarioCreado: ", usuarioCreado);

    try {
      console.log("Prueba de submit");
      dispatch(createUser(usuarioCreado));
      setLoadingx(true);
    } catch (error) {
      console.error("Error al crear laboratorio:", error);
      // Maneja el error aquí
    }
  };
  const opcionesSelectActivo = [
    {
      options: [
        { label: "Si", value: true },
        { label: "No", value: false },
      ],
    },
  ];

  return (
    <>
      {redireccionar && <Navigate to="/usuarios" replace />}
      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <h5 className="fw-semibold text-uppercase mb-3">Crear Cliente</h5>

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="nombre">
                  Nombre <span className="text-danger">*</span>
                </Label>

                <Input
                  type="text"
                  className="form-control"
                  id="nombre"
                  name="nombre"
                  required
                  minLength={3}
                  maxLength={50}
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="apellido">
                  Apellido <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="apellido"
                  name="apellido"
                  required
                  minLength={3}
                  maxLength={50}
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="correo">
                  Correo Electrónico <span className="text-danger">*</span>
                </Label>

                <Input
                  type="email"
                  className="form-control"
                  id="correo"
                  name="correo"
                  required
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="telefono">
                  Teléfono <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="telefono"
                  name="telefono"
                  required
                  minLength={11}
                  maxLength={11}
                  value={telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="usuario">
                  Usuario <span className="text-danger">*</span>
                </Label>

                <Input
                  type="text"
                  className="form-control"
                  id="usuario"
                  name="usuario"
                  minLength={5}
                  required
                  maxLength={50}
                  value={usuarioRol}
                  onChange={(e) => setUsuarioRol(e.target.value)}
                />
              </div>
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3 position-relative">
                <Label className="form-label" htmlFor="contrasena">
                  Contraseña <span className="text-danger">*</span>
                </Label>

                <div className="position-relative">
                  <Input
                    type="password"
                    className={`form-control pr-5 ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    id="contrasena"
                    name="contrasena"
                    required
                    minLength={8}
                    maxLength={50}
                    value={contrasena}
                    onChange={handlePasswordChange}
                  />

                  {/*             <button
              type="button"
              className={`btn btn-link position-absolute top-50 end-0 translate-middle-y ${showPassword ? "text-primary" : ""}`}
              onClick={() => setShowPassword(!showPassword)}
              aria-label="Toggle password visibility"
            >
              {showPassword ? (
                    <>
                      {" "}
                      <i className="ri-eye-off-fill fs-16"></i>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <i className="ri-eye-fill fs-16"></i>
                    </>
                  )}
            </button> */}

                  {passwordError && (
                    <div className="invalid-feedback">
                      <ul>
                        {passwordError.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="perfil">
                  Perfil <span className="text-danger">*</span>
                </Label>

                <Select
                  onChange={(selectedOption) => {
                    setPerfil(selectedOption.value);
                  }}
                  options={opcionesPerfil.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  required
                  id="perfil"
                  className="js-example-basic-single mb-0"
                  name="perfil"
                />
              </div>
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Activo <span className="text-danger">*</span>
                </Label>

                <Select
                  onChange={(selectedOption) => {
                    setActivo(selectedOption.value === "true");
                  }}
                  options={[
                    { value: "true", label: "Si" },
                    { value: "false", label: "No" },
                  ]}
                  required
                  id="activo"
                  className="js-example-basic-single mb-0"
                  name="activo"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Cliente
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default CrearUsuario;
