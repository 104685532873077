import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import logo_mh_plus_sm from "../../../assets/images/mh-sm.png";


  /**
   * Section es un componente que se encarga de mostrar los detalles de una
   * solicitud de medicamentos. Recibe como parámetro un objeto con la
   * información de la solicitud y devuelve un JSX con los detalles de la
   * solicitud.
   *
   * @param {object} props - Objeto con la información de la solicitud.
   *
   * @returns {JSX} Un JSX con los detalles de la solicitud.
   */
const Section = ( {solicitud} ) => {
  const [solicitante, setSolicitante] = useState(solicitud.solicitante);
  const [solicitanteCedula, setSolicitanteCedula] = useState(solicitud.solicitanteCedula);

  const [titular, setTitular] = useState(solicitud.titular);
  const [titularCedula, setTitularCedula] = useState(solicitud.titularCedula);


  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="mt-n4 mx-n4 mb-n5">
          <div className="bg-success-subtle">
            <CardBody className="pb-4 mb-5">
              <Row>
                <div className="col-md">
                  <Row className="align-items-center">
                    <div className="col-md-auto">
                      <div className="avatar-md mb-md-0 mb-4">
                        <div className="avatar-title bg-white rounded-circle">
                          <img
                            src={logo_mh_plus_sm}
                            alt=""
                            className="avatar-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <h4 className="fw-semibold" id="ticket-title">
                        #{solicitud.identificador} - Solicitud:{" "}
                        {solicitud.solicitante} - C.I: {solicitud.solicitanteCedula}
                      </h4>
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted">
                          {" "}
                          Titular:
                          <span className="fw-medium" id="cliente">
                            {" "}
                            {solicitud.titular}
                            
                          </span>{" "}
                          {" "}
                          C.I:
                          <span className="fw-medium" id="cliente">
                            {" "}
                            {solicitud.titularCedula}
                            
                          </span>{" "}
                        </div>
                        <div className="text-muted">
                          {" "}
                          Fecha Creación:{" "}
                          <span className="fw-medium" id="create-date">
                            {solicitud.creado}
                          </span>
                        </div>
                        <div className="vr"></div>
                        <div
                          className="badge rounded-pill bg-success fs-12"
                          id="ticket-status"
                        >
                          {solicitud.estatus}
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Section;
