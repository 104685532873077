import React, { useEffect, useState } from "react";
import {
  createPlanilla,
  resetPlanillasFlag,
  getLabs,
  getPrincActivos,
} from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Col, Row, Input, Label, Form, Alert, Spinner } from "reactstrap";
import Select from "react-select";
import { Navigate } from "react-router-dom";

/**
 * Página para crear una planilla estratégica, contiene un formulario
 * que pide el nombre, si es activa, los laboratorios no admitidos,
 * los principios activos no admitidos, la calidad farmacológica y
 * la calificación de imagen.
 * @param {Object} props - Propiedades del componente.
 * @returns {React.ReactElement} - Componente JSX.
 */
const Crear = (props) => {
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state;

  const pageData = createSelector(selectLayoutState, (state) => ({
    planilla: state.PlanillaEstrategica.planilla,
    error: state.PlanillaEstrategica.error,
    loading: state.PlanillaEstrategica.loading,
    errorMsg: state.PlanillaEstrategica.errorMsg,
    colorMsg: state.PlanillaEstrategica.colorMsg,
    exito: state.PlanillaEstrategica.exito,
  }));

  // Inside your component
  const { planilla, error, loading, errorMsg, colorMsg, exito } =
    useSelector(pageData);

  console.log("planillas:", planilla);
  console.log("error:", error);
  console.log("loading:", loading);
  console.log("errorMsg:", errorMsg);
  console.log("colorMsg:", colorMsg);
  console.log("exito:", exito);

  // Obtener los laboratorios
  const selectLabsState = (state) => state.Laboratorios;
  const labsPageData = createSelector(selectLabsState, (state) => state);
  // Inside your component
  const { laboratorios } = useSelector(labsPageData);

  console.log("laboratorios:", laboratorios[0]);

  // Obtener los principios activos
  const selectPrincActState = (state) => state.PrincipiosActivos;
  const princActPageData = createSelector(
    selectPrincActState,
    (state) => state
  );
  // Inside your component
  const { principiosActivos } = useSelector(princActPageData);

  console.log("principiosActivos:", principiosActivos[0]);

  //Atributos del Modelo
  const [nombre, setNombre] = useState("");
  const [laboratoriosNuevos, setLaboratoriosNuevos] = useState([]);
  const [principiosactivosNuevos, setPrincipiosactivosNuevos] = useState([]);
  const [activo, setActivo] = useState(false);

  const [calidadFarmacologica, setCalidadFarmacologica] = useState([
    ["A", 1],
    ["B", 1],
    ["C", 1],
    ["D", 1]
  ]);

  console.log("calidadFarmacologica:", calidadFarmacologica);
  
  const [calificacionImagen, setCalificacionImagen] = useState([
    ["A", 1],
    ["B", 1],
    ["C", 1],
  ]);

  console.log("calificacionImagen:", calificacionImagen);

  const [redireccionar, setRedireccionar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  useEffect(() => {
    dispatch(getLabs());
    dispatch(getPrincActivos());
  }, [dispatch]);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetPlanillasFlag());
        setLoadingx(false);
      }, 2000);
    }
  }, [dispatch, errorMsg]);

  useEffect(() => {
    if (exito) {
      setTimeout(() => {
        setRedireccionar(true);
      }, 2000);
    }
  }, [exito]);

  /**
   * Funcion que se encarga de crear una planilla estrategica.
   * @param {Event} event Evento que se dispara al hacer submit del formulario.
   * @returns {Promise<void>} Promesa que se resuelve cuando se ha creado la planilla estrategica.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    const obj = {
      nombre: nombre.trim(),
      laboratorios: laboratoriosNuevos,
      principiosactivos: principiosactivosNuevos,
      calidadFarmacologica: calidadFarmacologica,
      calificacionImagen: calificacionImagen,
      activo: activo,
      creado: null,
      actualizado: null,
    };

    console.log(obj);

    try {
      dispatch(createPlanilla(obj));
      setLoadingx(true);
    } catch (error) {
      console.error("Error al crear un Planilla Estrategica:", error);
      // Maneja el error aquí
    }
  };


  //  OBTENER LAS OPCIONES DE LABORATORIOS Y PRINCIPIOS ACTIVOS
  const getLaboratorioOptions = () => {
    return laboratorios[0]?.map((laboratorio) => ({
      value: laboratorio,
      label: laboratorio.nombre,
    }));
  };

  // OBTENER LAS OPCIONES DE PRINCIPIOS
  const getPrincipiosOptions = () => {
    return principiosActivos[0]?.map((principio) => ({
      value: principio,
      label: principio.nombre,
    }));
  };

  /**
   * Actualiza el estado de laboratoriosNuevos con los valores seleccionados.
   * @param {object[]} selectedOptions - Un array de objetos con las opciones seleccionadas.
   */
  function handleLaboratoriosChange(selectedOptions) {
    setLaboratoriosNuevos(selectedOptions.map((option) => option.value));
  }

  /**
   * Actualiza el estado de principiosactivosNuevos con los valores seleccionados.
   * @param {object[]} selectedOptions - Un array de objetos con las opciones seleccionadas.
   */
  function handlePrincipiosActivosChange(selectedOptions) {
    setPrincipiosactivosNuevos(selectedOptions.map((option) => option.value));
  }

  return (
    <>
      {redireccionar && <Navigate to="/planilla-estrategica" replace />}

      <React.Fragment>
        {errorMsg && errorMsg ? (
          <Alert color={colorMsg}> {error} </Alert>
        ) : null}

        <Form onSubmit={handleSubmit}>
          <Row lg={12} className="p-3">
            <Col xxl={6} md={6}>
              <div>
                <Label className="form-label" htmlFor="nombre">
                  Nombre <span className="text-danger">*</span>
                </Label>
              </div>
              <Input
                required
                minLength={3}
                maxLength={50}
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Activo <span className="text-danger">*</span>
                </Label>
                <Select
                  onChange={(selectedOption) => {
                    setActivo(selectedOption.value === "true");
                  }}
                  options={[
                    { value: "", label: "Seleccione una opción" },
                    { value: "true", label: "Si" },
                    { value: "false", label: "No" },
                  ]}
                  required
                  id="activo"
                  className="js-example-basic-single mb-0"
                  name="activo"
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Laboratorios No Admitidos{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  isMulti={true}
                  isClearable={true}
                  classNamePrefix="farme"
                  options={getLaboratorioOptions()}
                  required
                  id="laboratorios"
                  className="js-example-basic-single mb-0"
                  name="laboratorios"
                  onChange={handleLaboratoriosChange}
                />
              </div>
            </Col>
            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="activo">
                  Principios Activos No Admitidos
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  isMulti={true}
                  isClearable={true}
                  classNamePrefix="farme"
                  options={getPrincipiosOptions()}
                  required
                  id="principiosactivos"
                  className="js-example-basic-single mb-0"
                  name="principiosactivos"
                  onChange={handlePrincipiosActivosChange}
                />
              </div>
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="calidadFarmacologica">
                  Calidad Farmacológica
                </Label>
                {calidadFarmacologica.map((item, index) => (
                  <Input
                    type="number"
                    className="form-control mb-2"
                    id={`calidadFarmacologica-${index}`}
                    name={`calidadFarmacologica[${index}]`}
                    placeholder={`${item[0]}`}
                    onChange={(e) =>
                      setCalidadFarmacologica((prevState) =>
                        prevState.map((item, i) =>
                          i === index ? [item[0], parseInt(e.target.value)] : item
                        )
                      )
                    }
                  />
                ))}
              </div>
            </Col>

            <Col xxl={6} md={6}>
              <div className="mb-3">
                <Label className="form-label" htmlFor="calificacionImagen">
                  Calificacion Imagen
                </Label>
                {calificacionImagen?.map((item, index) => (
                  <Input
                    type="number"
                    className="form-control mb-2"
                    id={`calificacionImagen-${index}`}
                    name={`calificacionImagen[${index}]`}
                    placeholder={`${item[0]}`}
                    onChange={(e) =>
                      setCalificacionImagen((prevState) =>
                        prevState.map((item, i) =>
                          i === index ? [item[0], parseInt(e.target.value)] : item
                        )
                      )
                    }
                  />
                ))}
              </div>
            </Col>
          </Row>

          <Row className="align-items-end">
            <Col lg={12}>
              <div className="mt-4 mb-3 text-end">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  {loadingx ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                  Crear Planilla Estrategica
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    </>
  );
};

export default Crear;
