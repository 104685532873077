import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";

//import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//FARME
//Laboratorios
import LaboratoriosReducer from "./configuracion/laboratorios/reducer";
//Usuarios
import UsuariosReducer from "./configuracion/usuarios/reducer";
//PrincipiosActivos
import PrincipiosActivosReducer from "./configuracion/principiosactivos/reducer";
//Servicio De Entrega
import ServicioEntregasReducer from "./configuracion/serviciodeentrega/reducer";
//Planilla Estratégica
import PlanillaEstrategicaReducer from "./configuracion/planillaestrategica/reducer";
//Solicitudes
import SolicitudReducer from "./configuracion/solicitudes/reducer"


const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    //Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Laboratorios: LaboratoriosReducer,
    Usuarios: UsuariosReducer,
    PrincipiosActivos: PrincipiosActivosReducer,
    ServicioEntregas: ServicioEntregasReducer,
    PlanillaEstrategica: PlanillaEstrategicaReducer,
    Solicitudes: SolicitudReducer
   
});

export default rootReducer;