import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Table,
} from "reactstrap";
import { responsablesDelivery, datosSolicitudes } from "../../../common/data";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Solicitud from "./Solicitud";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const TicketDetails = ({
  solicitud,
  showResponsable,
  setShowResponsable,
  btnDely,
}) => {
  const [files, setFiles] = useState([]);
  const [emailDelivery, setEmailDelivery] = useState("");
  function handleEmail(e) {
    // Extraer el valor seleccionado del evento
    const selectedId = e.target.value;

    // Buscar el objeto solicitudPersona correspondiente por su ID
    const selectedSolicitudPersona = responsablesDelivery.find(
      (solicitud) => solicitud.id === parseInt(selectedId, 10)
    );

    // Ahora tienes acceso al objeto selectedSolicitudPersona completo
    setEmailDelivery(selectedSolicitudPersona.Phone);
  }

  return (
    <React.Fragment>
      <Col xxl={3}>
        <Solicitud solicitud={solicitud} />

        <Card>
          <CardHeader>
            <h6 className="card-title fw-semibold mb-0">Lista de Adjuntos</h6>
          </CardHeader>
          <CardBody>
            {/* Recipe */}
            <div className="d-flex align-items-center border border-dashed p-2 rounded">
              <div className="flex-shrink-0 avatar-sm">
                <div className="avatar-title bg-light rounded">
                  <i className=" ri-attachment-line fs-20 text-primary"></i>
                </div>
              </div>

              <div className="flex-grow-1 ms-3">
                <h6 className="mb-1">
                  <Link to={solicitud.recipe} target="_blank">
                    Recipe
                  </Link>
                </h6>
                <small className="text-muted">{solicitud.fechaRecipe}</small>
              </div>

              <div className="hstack gap-3 fs-16">
                <Link
                  to={solicitud.recipe}
                  target="_blank"
                  className="text-muted"
                >
                  <i className="ri-eye-line"></i>
                </Link>
              </div>
            </div>

            {/* Informe Medico */}

            <div className="d-flex align-items-center border border-dashed p-2 rounded">
              <div className="flex-shrink-0 avatar-sm">
                <div className="avatar-title bg-light rounded">
                  <i className=" ri-attachment-line fs-20 text-primary"></i>
                </div>
              </div>

              <div className="flex-grow-1 ms-3">
                <h6 className="mb-1">
                  <Link to={solicitud.informe} target="_blank">
                    Informe Médico
                  </Link>
                </h6>
                <small className="text-muted">{solicitud.fechaInforme}</small>
              </div>

              <div className="hstack gap-3 fs-16">
                <Link
                  to={solicitud.informe}
                  target="_blank"
                  className="text-muted"
                >
                  <i className="ri-eye-line"></i>
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">INFORMACIÓN DELIVERY</h5>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Recibe</td>
                    <td id="d-date">{solicitud.recibe}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Telefono</td>
                    <td id="d-date">{solicitud.telefono}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Direccion</td>
                    <td id="d-date">{solicitud.direccion}</td>
                  </tr>

                  <tr>
                    <td className="fw-medium">Coordendas</td>
                    <td id="d-date">
                      {solicitud.coordenadas ? (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${solicitud.coordenadas[0]},${solicitud.coordenadas[1]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${solicitud.coordenadas[0].toFixed(
                            6
                          )}, ${solicitud.coordenadas[1].toFixed(6)}`}
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="fw-medium">Observaciones</td>
                    <td id="d-date">{solicitud.observaciones}</td>
                  </tr>

                  {!btnDely ? null : (
                    <>
                      <tr>
                        <td className="fw-medium">Responsable</td>
                        <td>Ramon Antonio Velasquez</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Teléfono</td>
                        <td id="d-date">04247745113</td>
                      </tr>

                      <tr>
                        <td className="fw-medium">Fercha para la entrega</td>
                        <td>8/5/2024</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Hora de la entrega</td>
                        <td>8:10 pm</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        {!showResponsable ? null : (
          <Card>
            <CardHeader>
              <h6 className="card-title fw-semibold mb-0">Facturación</h6>
            </CardHeader>
            <CardBody>
              <Form>
                <div className="table-responsive table-card">
                  <Table className="table-borderless align-middle mb-0">
                    <tbody>
                      <tr>
                        <td className="fw-medium">Fecha</td>
                        <td id="d-date">8/5/2024</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Moneda</td>
                        <td id="d-date">DÓLAR</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Número de Factura</td>
                        <td id="d-date">#2344571</td>
                      </tr>
                      <tr>
                        <td className="fw-medium">Costo Total</td>
                        <td id="d-date">200$</td>
                      </tr>

                      <tr>
                        <td className="fw-medium ">Adjuntar Factura</td>
                        <td>Archivo adjunto</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
      </Col>
    </React.Fragment>
  );
};

export default TicketDetails;
