import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Table,
} from "reactstrap";
import { datosSolicitudes } from "../../../common/data";

const Solicitud = ({solicitud}) => {
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader>
            <h6 className="card-title fw-semibold mb-0">Solicitud</h6>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Detalle</td>
                    <td id="d-date">{solicitud.detalle}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Diágnostico</td>
                    <td id="d-date">{solicitud.diagnostico}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Tipo</td>
                    <td id="d-date">{solicitud.tipo}</td>
                  </tr>

                  {/* <tr>
                    <td className="fw-medium">Calificación</td>
                    <div className="col-md-auto mt-md-0 mt-4">
                      <div className="hstack gap-1 flex-wrap">
                        <button
                          type="button"
                          className="btn avatar-xs mt-n1 p-0 favourite-btn active"
                        >
                          <span className="avatar-title bg-transparent fs-15">
                            <i className="ri-star-fill"></i>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn avatar-xs mt-n1 p-0 favourite-btn active"
                        >
                          <span className="avatar-title bg-transparent fs-15">
                            <i className="ri-star-fill"></i>
                          </span>
                        </button>
                        <button
                          type="button"
                          className="btn avatar-xs mt-n1 p-0 favourite-btn active"
                        >
                          <span className="avatar-title bg-transparent fs-15">
                            <i className="ri-star-fill"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </tr>
                  <tr>
                    <td className="fw-medium">Comentarios</td>
                    <td id="d-date"><Input type="textarea" value="esto es lo que el usuario indica en su descripción" readOnly/></td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Solicitud;
